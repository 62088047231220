import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { IconComponent } from '@vs/ui/vs-icons';
import { normalize, schema } from 'normalizr';

import { ProductFamiliesQuery } from '../vsSubscriptionApiInjected';

type ProductConstant = {
  IconName?: string;
  nameI18n?: string;
  descI18n?: string;
  bannerDescI18n?: string;
  contentI18n?: string;
};

type PlanConstant = {
  nameI18n?: string;
};

type ProductConstants = Record<string, ProductConstant>;

type PlanConstants = Record<string, PlanConstant>;

type StandardProductConstant = Pick<
  ProductConstant,
  'IconName' | 'bannerDescI18n'
> &
  Required<Omit<ProductConstant, 'IconName' | 'bannerDescI18n'>>;

type ProductFamilyConstant = Record<
  string,
  {
    products: {
      standard?: StandardProductConstant;
    } & ProductConstants;
    plans: PlanConstants;
  }
>;

const productFamilyID = {
  MVB: 'a93e3317-f6b1-45e6-9912-3be84ec52bad',
};

const productID = {
  MVB_PRO: '8bdd2ec3-38f5-4fd2-a1e1-de8a179cec50',
  MVB_ENTITY: '6c46d07e-baab-4906-b4be-19b519fe6c98',
};

export const productIconMap: Record<string, IconComponent> = {
  MVB_STANDARD: VerifiedOutlinedIcon,
  MVB_PRO: EmojiEventsOutlinedIcon,
  MVB_ENTITY: AccountBalanceOutlinedIcon,
};

export const productFamilyConstant: ProductFamilyConstant = {
  // myViewboard
  [productFamilyID.MVB]: {
    products: {
      standard: {
        IconName: 'MVB_STANDARD',
        nameI18n: 'subscription.product.mvb.standard.name',
        descI18n: 'subscription.product.mvb.standard.desc',
        bannerDescI18n: 'subscription.product.mvb.standard.banner_desc',
        contentI18n: 'subscription.product.mvb.standard.content',
      },
      [productID.MVB_PRO]: {
        IconName: 'MVB_PRO',
        descI18n: 'subscription.product.mvb.pro.desc',
        contentI18n: 'subscription.product.mvb.pro.content',
      },
      [productID.MVB_ENTITY]: {
        IconName: 'MVB_ENTITY',
        descI18n: 'subscription.product.mvb.entity.desc',
        contentI18n: 'subscription.product.mvb.entity.content',
      },
    },
    plans: {},
  },
};

type ProductFamily = ProductFamiliesQuery['productFamilies'][0];
export type NormalizedProductFamily = Omit<
  ProductFamily,
  'components' | 'products'
> & {
  productIds: string[];
  standardProductConstant?: StandardProductConstant;
};

type Product = ProductFamily['products'][0];
type Component = ProductFamily['components'][0];
export type NormalizedProductComponentExtra = {
  planIds: string[];
  productFamilyId: string;
  constants?: ProductConstant;
};
export type NormalizedProduct = Omit<Product, 'plans'> &
  NormalizedProductComponentExtra;
export type NormalizedComponent = Omit<Component, 'plans'> &
  NormalizedProductComponentExtra;

export type DiscountInfo = {
  durationDay: number;
  price: number;
  priceWithBaseDuration: number;
  discount?: number;
};
type NormalizedProductComponentPlanExtra = {
  productOrComponentId: string;
  productFamilyId: string;
  constants?: PlanConstant;
  price: string;
  discountInfo: DiscountInfo;
};
export type NormalizedProductPlan = Product['plans'][0] &
  NormalizedProductComponentPlanExtra;
export type NormalizedComponentPlan = Component['plans'][0] &
  NormalizedProductComponentPlanExtra;

type NormalizedProductFamilies = {
  entities: {
    productFamilies: Record<string, NormalizedProductFamily>;
    products: Record<string, NormalizedProduct | NormalizedComponent>;
    plans: Record<string, NormalizedProductPlan | NormalizedComponentPlan>;
  };
  result: string[];
};

export function transformProductFamilies(resp: ProductFamiliesQuery) {
  const productPlansSchema = new schema.Entity('plans', undefined, {
    processStrategy: (
      value: NormalizedProductPlan,
      parent: NormalizedProduct
    ) => ({
      ...value,
      productOrComponentId: parent.id,
      productFamilyId: parent.productFamilyId,
    }),
  });
  const productsSchema = new schema.Entity(
    'products',
    {
      planIds: [productPlansSchema],
    },
    {
      processStrategy: (
        value: NormalizedProduct,
        parent: NormalizedProductFamily
      ) => ({ ...value, productFamilyId: parent.id }),
    }
  );
  const productFamiliesSchema = new schema.Entity(
    'productFamilies',
    { productIds: [productsSchema] },
    {
      idAttribute: 'id',
    }
  );
  const normalized = normalize(
    resp.productFamilies
      .map(({ products, components, ...restPf }) => {
        const allProducts = [...products, ...components].filter(
          pd => pd.is_publish && pd.plans.some(pl => pl.is_publish)
        );
        return {
          ...restPf,
          standardProductConstant:
            productFamilyConstant[restPf.id]?.products.standard,
          productIds: allProducts.map(({ plans, ...restPc }) => ({
            ...restPc,
            constants: productFamilyConstant[restPf.id]?.products[restPc.id],
            planIds: plans.map(p => ({
              ...p,
              constants: productFamilyConstant[restPf.id]?.plans[p.id],
              price: '0',
              discountInfo: {
                durationDay: 0,
                price: 0,
                priceWithBaseDuration: 0,
              },
            })),
          })),
        };
      })
      .filter(
        pf =>
          pf.productIds.length > 0 || pf.standardProductConstant !== undefined
      ),
    [productFamiliesSchema]
  ) as NormalizedProductFamilies;

  return {
    productFamilies: resp.productFamilies,
    normalizedProductFamilies: normalized,
  };
}
