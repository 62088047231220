import { useOidcUserProfile } from '@vs/oidc-client';
import { useAccountFindQuery } from '@vs/rtk/slices/apiVsAccount2';

type Opts = Parameters<typeof useAccountFindQuery>[1];

type Returns = ReturnType<typeof useAccountFindQuery>;

/**
 * Use Viewsonic account api rtk response
 *
 * @export
 * @param {Opts}
 * @returns {Returns}
 */
export function useAccountInfo(opts?: Opts): Returns {
  const oidcProfile = useOidcUserProfile();

  return useAccountFindQuery(
    {
      accountId: oidcProfile?.sub ?? '',
    },
    { ...opts, skip: !oidcProfile?.sub || opts?.skip }
  );
}

export default useAccountInfo;
