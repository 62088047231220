import {
  BaseGlobalState,
  hideToast,
  removeToast,
} from '@vs/rtk/slices/genericGlobal';
import { PortalToast } from '@vs/ui/portal/toast';
import { memo } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const useStateSelector: TypedUseSelectorHook<{ general: BaseGlobalState }> =
  useSelector;

/**
 * Show global toast meesage according to redux state.
 * * Redux store need to include `generalStateSlice`
 *
 * @export
 * @returns {ReactElement}
 */
export const PortalAppToast = memo(() => {
  const dispatch = useDispatch();
  const toastSettings = useStateSelector(s => s.general.toastSettings);

  return toastSettings.map(ts => (
    <PortalToast
      key={ts.id}
      open={ts.show}
      message={ts.message}
      duration={ts.duration}
      vertical={ts.vertical}
      horizontal={ts.horizontal}
      actionType={ts.actionType}
      onClose={() => {
        dispatch(hideToast({ id: ts.id }));
        setTimeout(() => dispatch(removeToast({ id: ts.id })), 1000);
      }}
    />
  ));
});

export default PortalAppToast;
