import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { waitForTokenRefresh } from '@vs/oidc-client';
import { DocumentNode } from 'graphql';
import { ClientError } from 'graphql-request';

import {
  RtkErrorResponseOfGraphQL,
  isDMAdminApi,
  isGraphQLRequestBaseQueryError,
  parseGraphQLRequestBaseQueryError,
} from './graphqlErrorHelper';
import { ReferenceOfMeta } from './type';

type ExtraOptions = Partial<Pick<ClientError, 'request' | 'response'>> & {
  isPublicEndpoint?: boolean;
};

type DynamicGraphBaseQueryFn = BaseQueryFn<
  { document: string | DocumentNode; variables?: any },
  unknown,
  unknown,
  ExtraOptions
>;

export const dynamicGraphqlBaseQuery =
  (referenceOfMeta: ReferenceOfMeta): DynamicGraphBaseQueryFn =>
  async (queryArgs, baseQueryApi, extraOptions = {}) => {
    const {
      _baseUrl,
      _getTokenFunc,
      _prepareGlobalHeaders = () => {},
    } = referenceOfMeta;

    const bs = graphqlRequestBaseQuery({
      url: _baseUrl || '',
      prepareHeaders: async (headers, api) => {
        await _prepareGlobalHeaders(headers);

        if (!extraOptions.isPublicEndpoint) {
          await waitForTokenRefresh();
          if (typeof _getTokenFunc === 'function') {
            const token = await _getTokenFunc();
            if (token) {
              headers.set('authorization', `Bearer ${token}`);
            }
          }
        }

        return headers;
      },
    });

    const result = Promise.resolve(bs(queryArgs, baseQueryApi, extraOptions));
    return result.then(payload => {
      if (payload.error && isDMAdminApi(referenceOfMeta._baseUrl ?? '')) {
        if (isGraphQLRequestBaseQueryError(payload.error)) {
          const graphqlErrors = parseGraphQLRequestBaseQueryError(
            payload.error
          );
          const error: RtkErrorResponseOfGraphQL = {
            status: graphqlErrors[0]?.extensions.statusCode || 500,
            data: graphqlErrors.map(err => ({
              message: err.message,
              path: err.path,
              extensions: err.extensions,
            })),
          };
          return { error };
        }
      }
      return payload;
    });
  };
