import { vsAccount2ApiBase as api } from './vsAccount2ApiBase';

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    accountFindDetail: build.query<
      AccountFindDetailApiResponse,
      AccountFindDetailApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/management/account/detail/${queryArg.shardRegion}/${queryArg.accountId}`,
      }),
    }),
    accountFindList: build.query<
      AccountFindListApiResponse,
      AccountFindListApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/management/account/list`,
        params: {
          cur_page: queryArg.curPage,
          page_size: queryArg.pageSize,
          account: queryArg.account,
        },
      }),
    }),
    accountFindAll: build.mutation<
      AccountFindAllApiResponse,
      AccountFindAllApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/management/account/list`,
        method: 'POST',
        body: queryArg.getAccountsDto,
      }),
    }),
    consentGet: build.query<ConsentGetApiResponse, ConsentGetApiArg>({
      query: () => ({ url: `/account/api/v2/management/consent` }),
    }),
    consentUpsert: build.mutation<
      ConsentUpsertApiResponse,
      ConsentUpsertApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/management/consent`,
        method: 'POST',
        body: queryArg.upsertConsentsDto,
      }),
    }),
    accountMarketingGet: build.query<
      AccountMarketingGetApiResponse,
      AccountMarketingGetApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/marketing`,
      }),
    }),
    accountMarketingUpdate: build.mutation<
      AccountMarketingUpdateApiResponse,
      AccountMarketingUpdateApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/marketing`,
        method: 'PATCH',
        body: queryArg.updateUserMarketingCampaignDto,
      }),
    }),
    accountFind: build.query<AccountFindApiResponse, AccountFindApiArg>({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/profile`,
      }),
    }),
    accountUpdate: build.mutation<
      AccountUpdateApiResponse,
      AccountUpdateApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/profile`,
        method: 'PATCH',
        body: queryArg.updateAccountDto,
      }),
    }),
    accountGetEntityInfo: build.query<
      AccountGetEntityInfoApiResponse,
      AccountGetEntityInfoApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/entity-info`,
      }),
    }),
    ssoGet: build.query<SsoGetApiResponse, SsoGetApiArg>({
      query: () => ({ url: `/account/api/v2/account/sso` }),
    }),
    ssoSsoUri: build.query<SsoSsoUriApiResponse, SsoSsoUriApiArg>({
      query: queryArg => ({
        url: `/account/api/v2/account/sso/uri`,
        params: {
          country_code: queryArg.countryCode,
          return_uri: queryArg.returnUri,
        },
      }),
    }),
    userAvatarGet: build.query<UserAvatarGetApiResponse, UserAvatarGetApiArg>({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/avatar`,
      }),
    }),
    userAvatarUpdate: build.mutation<
      UserAvatarUpdateApiResponse,
      UserAvatarUpdateApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/avatar`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    userAvatarDelete: build.mutation<
      UserAvatarDeleteApiResponse,
      UserAvatarDeleteApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/avatar`,
        method: 'DELETE',
      }),
    }),
    userMfaGet: build.query<UserMfaGetApiResponse, UserMfaGetApiArg>({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/mfa/methods`,
      }),
    }),
    userSsoGet: build.query<UserSsoGetApiResponse, UserSsoGetApiArg>({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/sso`,
      }),
    }),
    userSsoUpdate: build.mutation<
      UserSsoUpdateApiResponse,
      UserSsoUpdateApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/sso`,
        method: 'POST',
        body: queryArg.updateUserSsoDto,
      }),
    }),
    userSsoDelete: build.mutation<
      UserSsoDeleteApiResponse,
      UserSsoDeleteApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/sso`,
        method: 'DELETE',
        body: queryArg.deleteUserSsoDto,
      }),
    }),
    userSsoCheck: build.mutation<UserSsoCheckApiResponse, UserSsoCheckApiArg>({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/sso/check`,
        method: 'POST',
        body: queryArg.updateUserSsoDto,
        params: { sso_code: queryArg.ssoCode },
      }),
    }),
    managementAccountUpdateStatus: build.mutation<
      ManagementAccountUpdateStatusApiResponse,
      ManagementAccountUpdateStatusApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/management/account/status`,
        method: 'PUT',
        body: queryArg.bulkUpdateStatusDto,
      }),
    }),
    managementAccountUpdatePassword: build.mutation<
      ManagementAccountUpdatePasswordApiResponse,
      ManagementAccountUpdatePasswordApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/management/account/${queryArg.accountId}/password`,
        method: 'PATCH',
        body: queryArg.resetPasswordDto,
      }),
    }),
    accountSendVerifyEmail: build.query<
      AccountSendVerifyEmailApiResponse,
      AccountSendVerifyEmailApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/send/verify-email`,
      }),
    }),
    accountSendDeleteEmail: build.query<
      AccountSendDeleteEmailApiResponse,
      AccountSendDeleteEmailApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/send/delete-email`,
      }),
    }),
    accountSendResetPassword: build.mutation<
      AccountSendResetPasswordApiResponse,
      AccountSendResetPasswordApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/send/reset-password`,
        method: 'POST',
        body: queryArg.sendWithEmailAndRefererDto,
      }),
    }),
    accountVerifyEmail: build.mutation<
      AccountVerifyEmailApiResponse,
      AccountVerifyEmailApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/account/verify-email`,
        method: 'PUT',
        params: { hash_id: queryArg.hashId },
      }),
    }),
    accountDeleteUser: build.mutation<
      AccountDeleteUserApiResponse,
      AccountDeleteUserApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/account/delete`,
        method: 'DELETE',
        params: { hash_id: queryArg.hashId },
      }),
    }),
    accountCredentialUpdate: build.mutation<
      AccountCredentialUpdateApiResponse,
      AccountCredentialUpdateApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/password`,
        method: 'PATCH',
        body: queryArg.updatePasswordDto,
      }),
    }),
    userMfaGetQRcode: build.mutation<
      UserMfaGetQRcodeApiResponse,
      UserMfaGetQRcodeApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/mfa/authenticator/uri`,
        method: 'POST',
      }),
    }),
    userMfaVerifyOtp: build.mutation<
      UserMfaVerifyOtpApiResponse,
      UserMfaVerifyOtpApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/mfa/verify`,
        method: 'POST',
        body: queryArg.verifyOtpDto,
      }),
    }),
    userMfaDelete: build.mutation<
      UserMfaDeleteApiResponse,
      UserMfaDeleteApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/mfa`,
        method: 'DELETE',
        body: queryArg.deleteMfaDto,
      }),
    }),
    userMfaSetDefault: build.mutation<
      UserMfaSetDefaultApiResponse,
      UserMfaSetDefaultApiArg
    >({
      query: queryArg => ({
        url: `/account/api/v2/account/${queryArg.accountId}/mfa/default`,
        method: 'POST',
        body: queryArg.setMfaAsDefaultDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as vsAccount2ApiInjected };
export type AccountFindDetailApiResponse =
  /** status 200  */ AccountPiiDetailResponseDto;
export type AccountFindDetailApiArg = {
  accountId: string;
  /** shard region */
  shardRegion: any;
};
export type AccountFindListApiResponse =
  /** status 200  */ AccountListResponseDto;
export type AccountFindListApiArg = {
  /** current page */
  curPage?: number;
  /** page size */
  pageSize?: number;
  /** account email for searching */
  account?: string;
};
export type AccountFindAllApiResponse =
  /** status 200  */ GetAccountListResponseDto[];
export type AccountFindAllApiArg = {
  getAccountsDto: GetAccountsDto;
};
export type ConsentGetApiResponse = unknown;
export type ConsentGetApiArg = void;
export type ConsentUpsertApiResponse = unknown;
export type ConsentUpsertApiArg = {
  upsertConsentsDto: UpsertConsentsDto;
};
export type AccountMarketingGetApiResponse =
  /** status 200  */ GetUserMarketingResponseDto;
export type AccountMarketingGetApiArg = {
  accountId: string;
};
export type AccountMarketingUpdateApiResponse = unknown;
export type AccountMarketingUpdateApiArg = {
  accountId: string;
  updateUserMarketingCampaignDto: UpdateUserMarketingCampaignDto;
};
export type AccountFindApiResponse =
  /** status 200  */ GetAccountFullInfoResponseDto;
export type AccountFindApiArg = {
  accountId: string;
};
export type AccountUpdateApiResponse = unknown;
export type AccountUpdateApiArg = {
  accountId: string;
  updateAccountDto: UpdateAccountDto;
};
export type AccountGetEntityInfoApiResponse =
  /** status 200  */ GetAccountEntityInfoResponseDto;
export type AccountGetEntityInfoApiArg = {
  accountId: string;
};
export type SsoGetApiResponse = /** status 200  */ GetSsoResponseDto[];
export type SsoGetApiArg = void;
export type SsoSsoUriApiResponse = /** status 200  */ SsouriResponseDto;
export type SsoSsoUriApiArg = {
  countryCode: string;
  returnUri: string;
};
export type UserAvatarGetApiResponse =
  /** status 200  */ GetUserAvatarResponseDto;
export type UserAvatarGetApiArg = {
  accountId: string;
};
export type UserAvatarUpdateApiResponse = unknown;
export type UserAvatarUpdateApiArg = {
  accountId: string;
  /** Avatar for 48x48 px and 150x150 px. */
  body: {
    images?: Blob[];
  };
};
export type UserAvatarDeleteApiResponse = unknown;
export type UserAvatarDeleteApiArg = {
  accountId: string;
};
export type UserMfaGetApiResponse = /** status 200  */ UserMfaListResponseDto[];
export type UserMfaGetApiArg = {
  accountId: string;
};
export type UserSsoGetApiResponse =
  /** status 200  */ GetAccountSsoResponseDto[];
export type UserSsoGetApiArg = {
  accountId: string;
};
export type UserSsoUpdateApiResponse = unknown;
export type UserSsoUpdateApiArg = {
  accountId: string;
  updateUserSsoDto: UpdateUserSsoDto;
};
export type UserSsoDeleteApiResponse = unknown;
export type UserSsoDeleteApiArg = {
  accountId: string;
  deleteUserSsoDto: DeleteUserSsoDto;
};
export type UserSsoCheckApiResponse =
  /** status 200  */ CheckAccountSsoResponseDto;
export type UserSsoCheckApiArg = {
  ssoCode:
    | 'microsoft'
    | 'google'
    | 'apple'
    | 'facebook'
    | 'moe'
    | 'knsh'
    | 'clever'
    | 'classlink'
    | 'nyc';
  accountId: string;
  updateUserSsoDto: UpdateUserSsoDto;
};
export type ManagementAccountUpdateStatusApiResponse = unknown;
export type ManagementAccountUpdateStatusApiArg = {
  bulkUpdateStatusDto: BulkUpdateStatusDto;
};
export type ManagementAccountUpdatePasswordApiResponse = unknown;
export type ManagementAccountUpdatePasswordApiArg = {
  accountId: string;
  resetPasswordDto: ResetPasswordDto;
};
export type AccountSendVerifyEmailApiResponse = unknown;
export type AccountSendVerifyEmailApiArg = {
  accountId: string;
};
export type AccountSendDeleteEmailApiResponse = unknown;
export type AccountSendDeleteEmailApiArg = {
  accountId: string;
};
export type AccountSendResetPasswordApiResponse = unknown;
export type AccountSendResetPasswordApiArg = {
  accountId: string;
  sendWithEmailAndRefererDto: SendWithEmailAndRefererDto;
};
export type AccountVerifyEmailApiResponse = unknown;
export type AccountVerifyEmailApiArg = {
  hashId: string;
};
export type AccountDeleteUserApiResponse = unknown;
export type AccountDeleteUserApiArg = {
  hashId: string;
};
export type AccountCredentialUpdateApiResponse = unknown;
export type AccountCredentialUpdateApiArg = {
  accountId: string;
  updatePasswordDto: UpdatePasswordDto;
};
export type UserMfaGetQRcodeApiResponse =
  /** status 200  */ GetQrCodeResponseDto;
export type UserMfaGetQRcodeApiArg = {
  accountId: string;
};
export type UserMfaVerifyOtpApiResponse = unknown;
export type UserMfaVerifyOtpApiArg = {
  accountId: string;
  verifyOtpDto: VerifyOtpDto;
};
export type UserMfaDeleteApiResponse = unknown;
export type UserMfaDeleteApiArg = {
  accountId: string;
  deleteMfaDto: DeleteMfaDto;
};
export type UserMfaSetDefaultApiResponse = unknown;
export type UserMfaSetDefaultApiArg = {
  accountId: string;
  setMfaAsDefaultDto: SetMfaAsDefaultDto;
};
export type AccountPiiDetailResponseDto = {
  id: string;
  account: string;
  given_name: string;
  family_name: string;
  shard_region: object;
  status: Status;
  create_time: string;
};
export type MgmtAccountResponseDto = {
  id: string;
  account: string;
  shard_region: object;
  create_time: string;
};
export type PaginationMetaResponseDto = {
  current_page: number;
  per_page: number;
  total_items: number;
  total_pages: number;
};
export type AccountListResponseDto = {
  data: MgmtAccountResponseDto[];
  meta: PaginationMetaResponseDto;
};
export type GetAccountListResponseDto = {
  id: string;
  account: string;
  email_is_verified: boolean;
  email?: string;
  given_name?: string;
  family_name?: string;
  shard_region: string;
  create_time: string;
  modify_time: string;
};
export type GetAccountsDto = {
  /** id list of array. */
  ids: string[];
  /** account email list of array. */
  accounts: string[];
  /** email list of array. */
  details: boolean;
};
export type UpsertConsentsDto = {
  data: UpsertConsentsDto[];
};
export type GetUserMarketingResponseDto = {
  id: string;
  email_campaign: boolean;
};
export type UpdateUserMarketingCampaignDto = {
  email_campaign: boolean;
};
export type ConsentDetailResponseDto = {
  code: string;
  agreement: boolean;
};
export type GetAccountFullInfoResponseDto = {
  id: string;
  account: string;
  email: string;
  email_is_verified: boolean;
  has_password: boolean;
  display_name: string;
  given_name: string;
  family_name: string;
  phone_number: string;
  occupation_code: string;
  shard_region: string;
  country_code: string;
  region: string;
  city: string;
  avatar_url: string;
  lang_code: string;
  /** list of user role */
  role_code: string[];
  status: Status;
  marketing: boolean;
  create_time: string;
  modify_time: string;
  consent?: ConsentDetailResponseDto[];
};
export type UpdateAccountDto = {
  id: string;
  email?: string;
  shard_region: Shard_region;
  display_name?: string;
  given_name?: string;
  family_name?: string;
  phone?: string;
  occupation_code?: string;
  country_code: string;
  /** country */
  region?: string;
  city?: string;
  avatar_url?: string;
  lang_code?: string;
};
export type EntityInfoProfileDetailResponseDto = {
  contact_email: string;
  contact_phone: string;
  zipcode: string;
  country_code: string;
  country: string;
  region: string;
  city: string;
  address: string;
};
export type EntityInfoDomainDetailResponseDto = {
  is_verified: boolean;
  verify_type: string;
  domain_name: string;
  is_main: boolean;
};
export type EntityInfoEntitiesDetailResponseDto = {
  id: string;
  name: string;
  is_current: boolean;
  is_owner: boolean;
  is_su: boolean;
  policy_codes: any[];
};
export type GetAccountEntityInfoDetailResponseDto = {
  id: string;
  name: string;
  generics_code: string;
  domain_type_code: string;
  area: string;
  create_time: string;
  modify_time: string;
  profile: EntityInfoProfileDetailResponseDto;
  domains: EntityInfoDomainDetailResponseDto[];
  entities: EntityInfoEntitiesDetailResponseDto[];
};
export type GetAccountEntityInfoResponseDto = {
  data: GetAccountEntityInfoDetailResponseDto;
};
export type GetSsoResponseDto = {
  code: string;
  name: string;
};
export type SsouriDetailDto = {
  code: string;
  name: string;
  url: string;
};
export type SsouriResponseDto = {
  microsoft: SsouriDetailDto;
  google: SsouriDetailDto;
  apple: SsouriDetailDto;
  facebook: SsouriDetailDto;
  nyc: SsouriDetailDto;
  knsh: SsouriDetailDto;
  classlink: SsouriDetailDto;
};
export type GetUserAvatarResponseDto = {
  id: string;
  avatar_url: string;
};
export type UserMfaListResponseDto = {
  method: Method;
  enabled: boolean;
  isDefault: boolean;
  phone?: string;
  email?: string;
};
export type SsoDetails = {
  client_id?: string;
  entity_id?: string;
  external_account?: string;
  email?: string;
};
export type GetAccountSsoResponseDto = {
  id: string;
  account_id: string;
  sso_code: string;
  account: string;
  sso_details?: SsoDetails;
};
export type UpdateUserSsoDto = {
  sso_code: Sso_code;
  sso_details: object;
};
export type DeleteUserSsoDto = {
  sso_code: Sso_code;
};
export type CheckAccountSsoResponseDto = {
  id: string;
  account: string;
  sso_code: Sso_code;
  sso_principle_id: string;
  sso_details: object;
};
export type UpdateStatusDto = {
  /** Account id. */
  id: string;
  status: Status;
};
export type BulkUpdateStatusDto = {
  data: UpdateStatusDto[];
};
export type ResetPasswordDto = {
  password: string;
};
export type SendWithEmailAndRefererDto = {
  account_id: string;
  referer_uri: string;
};
export type UpdatePasswordDto = {
  /** Account id. */
  id: string;
  password: string;
  new_password: string;
};
export type GetQrCodeResponseDto = {
  uri: string;
};
export type VerifyOtpDto = {
  method: Method;
  token?: string;
};
export type DeleteMfaDto = {
  method: Method;
  password: string;
};
export type SetMfaAsDefaultDto = {
  mfaCode: MfaCode;
};
export enum Status {
  Active = 'active',
  Inactive = 'inactive',
  Locked = 'locked',
  Suspended = 'suspended',
  Deleted = 'deleted',
}
export enum Shard_region {
  Us = 'US',
  De = 'DE',
  Ca = 'CA',
  Sg = 'SG',
}
export enum Method {
  Email = 'email',
  Phone = 'phone',
  Authenticator = 'authenticator',
}
export enum Sso_code {
  Microsoft = 'microsoft',
  Google = 'google',
  Apple = 'apple',
  Facebook = 'facebook',
  Moe = 'moe',
  Knsh = 'knsh',
  Clever = 'clever',
  Classlink = 'classlink',
  Nyc = 'nyc',
}
export enum MfaCode {
  Email = 'email',
  Phone = 'phone',
  Authenticator = 'authenticator',
}
export const {
  useAccountFindDetailQuery,
  useAccountFindListQuery,
  useAccountFindAllMutation,
  useConsentGetQuery,
  useConsentUpsertMutation,
  useAccountMarketingGetQuery,
  useAccountMarketingUpdateMutation,
  useAccountFindQuery,
  useAccountUpdateMutation,
  useAccountGetEntityInfoQuery,
  useSsoGetQuery,
  useSsoSsoUriQuery,
  useUserAvatarGetQuery,
  useUserAvatarUpdateMutation,
  useUserAvatarDeleteMutation,
  useUserMfaGetQuery,
  useUserSsoGetQuery,
  useUserSsoUpdateMutation,
  useUserSsoDeleteMutation,
  useUserSsoCheckMutation,
  useManagementAccountUpdateStatusMutation,
  useManagementAccountUpdatePasswordMutation,
  useAccountSendVerifyEmailQuery,
  useAccountSendDeleteEmailQuery,
  useAccountSendResetPasswordMutation,
  useAccountVerifyEmailMutation,
  useAccountDeleteUserMutation,
  useAccountCredentialUpdateMutation,
  useUserMfaGetQRcodeMutation,
  useUserMfaVerifyOtpMutation,
  useUserMfaDeleteMutation,
  useUserMfaSetDefaultMutation,
} = injectedRtkApi;
