import { defaultAvatarUrl } from '@vs/utils/constants';
import { Img } from 'react-image';

export interface PortalAvatarUserProfileCardProps {
  title: string;
  name: string;
  avatarUrl?: string;
  email?: string | null;
  link?: {
    href: string;
    label: string;
  };
}

export function PortalAvatarUserProfileCard({
  name,
  avatarUrl,
  email,
  link,
  title,
}: PortalAvatarUserProfileCardProps) {
  return (
    <div className="px-6 pt-4">
      <div className="vs-body-xs-regular text-vs-base-content-variant leading-5">
        {title}
      </div>
      <div className=" flex items-center space-x-4 min-h-[80px]">
        <div className="flex-shrink-0 w-[56px] h-[56px]">
          <Img
            src={[avatarUrl || defaultAvatarUrl, defaultAvatarUrl]}
            alt={name}
            width={56}
            height={56}
          />
        </div>
        <div className="flex flex-col gap-1">
          <div className="vs-heading-xs-bold leading-7">
            <span className="">{name}</span>
          </div>
          {email && <div className="vs-body-xs-regular leading-5">{email}</div>}
          {link && (
            <div className="leading-3">
              <a
                className="text-vs-secondary vs-body-xs-regular underline"
                href={link?.href}
                target="_blank"
                rel="noreferrer"
              >
                {link?.label}
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="border-vs-neut-300 border-b w-full mt-4"></div>
    </div>
  );
}

export default PortalAvatarUserProfileCard;
