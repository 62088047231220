import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ArgsInitSliceMeta,
  ReferenceOfMeta,
  dynamicGraphqlBaseQuery,
} from '@vs/rtk/utils';
import { getEnvConfig } from '@vs/utils/envConfig';

const referenceOfMeta: ReferenceOfMeta = {
  _deployEnv: undefined,
  _baseUrl: undefined,
  _getTokenFunc: undefined,
};

export function initSliceMeta({ deployEnv, getTokenFunc }: ArgsInitSliceMeta) {
  referenceOfMeta._deployEnv = deployEnv;
  referenceOfMeta._baseUrl = getEnvConfig({
    deployEnv,
  }).apiEndpoint.vsSubscription;
  referenceOfMeta._getTokenFunc = getTokenFunc as () => Promise<string | null>;
}

export const VsSubscriptionGraphqlApiBase = createApi({
  reducerPath: 'vsSubscriptionGraphqlApi',
  baseQuery: dynamicGraphqlBaseQuery(referenceOfMeta),
  endpoints: () => ({}),
  tagTypes: [],
  keepUnusedDataFor: 60,
  refetchOnMountOrArgChange: 60,
});
