import { useAccountInfo } from '@vs/hooks/hook-account-info';

type Opts = Parameters<typeof useAccountInfo>[0];

type Returns = {
  /**
   * indicate user is able to use entity signup form app
   */
  isUser: boolean;
  /**
   * indicate api data is fetching, should use {@link Returns.isUser} after initialized
   */
  isInitializing: boolean;
};

/**
 * use flag whether user can access entity signup form app
 *
 * @export
 * @returns {Returns}
 */
export function useIsEntitySignupFormUser(opts?: Opts): Returns {
  const { data: account, isLoading, isUninitialized } = useAccountInfo(opts);

  const email = account?.email ?? '';
  const isAdminUser = account?.role_code.includes('admin');

  return {
    isUser: isAdminUser || email.endsWith('@viewsonic.com'),
    isInitializing: isUninitialized || isLoading,
  };
}

export default useIsEntitySignupFormUser;
