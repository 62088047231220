import { merge } from 'lodash';
import { PartialDeep } from 'type-fest';

import { env as devEnv } from './env/dev';
import { Env } from './env/env.type';
import { env as prodEnv } from './env/prod';
import { env as stageEnv } from './env/stage';

export type DeployEnv = 'prod' | 'stage' | 'dev';

export type OptsGetEnvConfig = {
  /**
   * overwrite config in localhost mode
   * @default {}
   */
  localOverwrite?: PartialDeep<Env>;
  /**
   * deploy env
   * @default 'dev'
   */
  deployEnv?: DeployEnv;
};

/**
 * Generate app config by deploy env
 *
 * @export
 * @param {OptsGetEnvConfig} opts
 * @returns {Env}
 */
export function getEnvConfig(opts: OptsGetEnvConfig): Env {
  const { deployEnv = 'dev', localOverwrite = {} } = opts;
  if (
    typeof window !== 'undefined' &&
    window.location.origin.includes('localhost')
  ) {
    return merge(
      {},
      deployEnv === 'prod'
        ? prodEnv
        : deployEnv === 'stage'
          ? stageEnv
          : devEnv,
      localOverwrite
    ) as Env;
  } else if (deployEnv === 'dev') {
    return devEnv;
  } else if (deployEnv === 'stage') {
    return stageEnv;
  }
  return prodEnv;
}
