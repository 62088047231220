import { FetchBaseQueryArgs } from '@reduxjs/toolkit/query/react';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { waitForTokenRefresh } from '@vs/oidc-client';

import {
  RtkErrorResponseOfVsApi,
  isErrorResponseOfVsApi,
  transformErrorResponseOfVsApi,
} from './errorHelper';
import { ReferenceOfMeta } from './type';

type ExtraOptions = {
  isPublicEndpoint?: boolean;
  fallbackResponseOfNotFound?: unknown;
  checkIsNotFoundError?: (
    error: FetchBaseQueryError | RtkErrorResponseOfVsApi
  ) => boolean;
};

type DynamicBaseQueryFn = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError | RtkErrorResponseOfVsApi,
  ExtraOptions
>;

export function dynamicFetchBaseFactory(
  referenceOfMeta: ReferenceOfMeta,
  baseQueryOption?: FetchBaseQueryArgs
): DynamicBaseQueryFn {
  return async (args, api, extraOptions = {}) => {
    const {
      _baseUrl,
      _getTokenFunc,
      _prepareGlobalHeaders = () => {},
    } = referenceOfMeta;

    const bs = fetchBaseQuery({
      baseUrl: _baseUrl ?? '',
      prepareHeaders: async (headers, api) => {
        await _prepareGlobalHeaders(headers);

        if (!extraOptions.isPublicEndpoint) {
          await waitForTokenRefresh();
          if (typeof _getTokenFunc === 'function') {
            const token = await _getTokenFunc();
            if (token) {
              headers.set('authorization', `Bearer ${token}`);
            }
          }
        }

        return headers;
      },
      credentials: 'omit',
      ...baseQueryOption,
    }) as DynamicBaseQueryFn;

    const result = await Promise.resolve(bs(args, api, extraOptions));

    if (result.error && isErrorResponseOfVsApi(result.error)) {
      if (
        extraOptions.fallbackResponseOfNotFound &&
        (typeof extraOptions.checkIsNotFoundError === 'function'
          ? extraOptions.checkIsNotFoundError(result.error)
          : result.error.status === 404)
      ) {
        // @ts-expect-error: this is run time mutation of RTK result, skip type issue
        result.data = extraOptions.fallbackResponseOfNotFound;
        // @ts-expect-error: this is run time mutation of RTK result, skip type issue
        delete result.error;
      } else {
        result.error = transformErrorResponseOfVsApi(result.error);
      }
    }
    return result;
  };
}
