export enum LocalStorageKeys {
  VS_PORTAL_LOCALE = 'vs_portal_locale',
}

export type PortalLayoutMode = 'app' | 'doc';

export type PortalLayoutSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';

export enum SubscriptionStatus {
  TRIALING = 'trialing',
  TRIAL_PENDING_CANCELLED = 'trial_pending_cancelled',
  TRIAL_ENDED = 'trial_ended',
  ACTIVE = 'active',
  PENDING_PAYMENT = 'pending_payment',
  MISSING_PAYMENT = 'missing_payment',
  PENDING_CANCELLED = 'pending_cancelled',
  CANCELLED = 'cancelled',
  DIRECTLY_CANCELLED = 'directly_cancelled',
}

export type SsoPopupMsgPayload = {
  success: boolean;
  errorMsg: '' | 'invalid_client';
};
