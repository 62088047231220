import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PortalToastProps } from '@vs/ui/portal/toast';
import type { SetRequired } from 'type-fest';
import { v4 as uuidv4 } from 'uuid';

export type ToastSetting = {
  id: string;
  show: boolean;
  message: string;
  duration: number;
  vertical: PortalToastProps['vertical'];
  horizontal: PortalToastProps['horizontal'];
  actionType: PortalToastProps['actionType'];
};

export type BaseGlobalState = {
  openDrawer: boolean;
  isLoadingTarget: Record<string, boolean>;
  toastSettings: ToastSetting[];
};

const toastSettingTemplate: ToastSetting = {
  id: '',
  show: true,
  message: '',
  duration: 5000,
  vertical: 'bottom',
  horizontal: 'center',
  actionType: null,
};

const initialBaseState: BaseGlobalState = {
  openDrawer: false,
  isLoadingTarget: {},
  toastSettings: [],
};

export const generalStateSlice = createSlice({
  name: 'general',
  initialState: initialBaseState,
  reducers: {
    setOpenDrawer: (state, action: PayloadAction<boolean>) => {
      state.openDrawer = action.payload;
    },
    setLoadingTarget: (
      state,
      action: PayloadAction<{ targetId: string; status: boolean }>
    ) => {
      const { status, targetId } = action.payload;
      if (status) {
        state.isLoadingTarget[targetId] = true;
      } else {
        delete state.isLoadingTarget[targetId];
      }
    },
    showToast: (
      state,
      action: PayloadAction<
        SetRequired<Partial<Omit<ToastSetting, 'id'>>, 'message'>
      >
    ) => {
      state.toastSettings.push({
        ...toastSettingTemplate,
        id: uuidv4(),
        ...action.payload,
      });
    },
    hideToast: (state, action: PayloadAction<Pick<ToastSetting, 'id'>>) => {
      const targetIndex = state.toastSettings.findIndex(
        t => t.id === action.payload.id
      );
      if (targetIndex >= 0) {
        state.toastSettings[targetIndex].show = false;
      }
    },
    removeToast: (state, action: PayloadAction<Pick<ToastSetting, 'id'>>) => {
      state.toastSettings = state.toastSettings.filter(
        t => t.id !== action.payload.id
      );
    },
  },
});

export const {
  setOpenDrawer,
  showToast,
  hideToast,
  removeToast,
  setLoadingTarget,
} = generalStateSlice.actions;
