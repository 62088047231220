import { Mutex, MutexInterface } from 'async-mutex';

const _tokenRefreshMutex = new Mutex();

let _tokenRefreshMutexReleasor: MutexInterface.Releaser | undefined;

export async function lockForTokenRefresh() {
  if (!_tokenRefreshMutexReleasor) {
    _tokenRefreshMutexReleasor = await _tokenRefreshMutex.acquire();
  }
}

export function releaseLockForTokenRefresh() {
  if (_tokenRefreshMutexReleasor) {
    _tokenRefreshMutexReleasor();
    _tokenRefreshMutexReleasor = undefined;
  }
}

export async function waitForTokenRefresh() {
  if (_tokenRefreshMutex.isLocked()) {
    await _tokenRefreshMutex.waitForUnlock();
  }
}
