import {
  initSliceMeta as initSubscriptionSliceMeta,
  vsSubscriptionApiSlice,
} from '@react-mono/rtk/slices/api-vs-subscription';
import { configureStore } from '@reduxjs/toolkit';
import {
  apiMiscellaneousSlice,
  initSliceMeta as initApiMiscellaneous,
} from '@vs/rtk/slices/apiMiscellaneous';
import {
  genReduxPersistKey,
  initSliceMeta as initAccount2SliceMeta,
  vsAccount2ApiSlice,
} from '@vs/rtk/slices/apiVsAccount2';
import {
  initSliceMeta as initAuthSliceMeta,
  vsAuthApiSlice,
} from '@vs/rtk/slices/apiVsAuth';
import {
  initSliceMeta as initCommonSliceMeta,
  vsCommonApiSlice,
} from '@vs/rtk/slices/apiVsCommon';
import localforage from 'localforage';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';

import { deployEnv, isLocalDevMode } from '../constants';
import getUserAccessToken from '../utils/getUserAccessToken';
import { rootReducer } from './reducer';
import { securityApiSlice } from './slices/api/security/security-api';
import { rtkQueryErrorLogger } from './slices/middleware/errorHandler';

const meta = {
  deployEnv,
  getTokenFunc: getUserAccessToken,
};

initCommonSliceMeta(meta);
initAccount2SliceMeta(meta);
initAuthSliceMeta(meta);
initSubscriptionSliceMeta(meta);
initApiMiscellaneous(meta);

const key = genReduxPersistKey();

const whitelist: string[] = [vsAccount2ApiSlice.reducerPath];

export const store = configureStore({
  devTools: isLocalDevMode,
  reducer: persistReducer(
    {
      key,
      version: 1,
      storage: localforage,
      whitelist,
    },
    rootReducer
  ),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      /* Opt-out these actions when use redux persist with Redux toolkit, see issue:
         https://github.com/rt2zz/redux-persist/issues/1455
      */
      serializableCheck: {
        ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE],
      },
    }).concat([
      securityApiSlice.middleware,
      vsAccount2ApiSlice.middleware,
      vsCommonApiSlice.middleware,
      vsAuthApiSlice.middleware,
      vsSubscriptionApiSlice.middleware,
      apiMiscellaneousSlice.middleware,
      rtkQueryErrorLogger,
    ]),
});

export const persistor = persistStore(store);

if (isLocalDevMode && import.meta.hot) {
  import.meta.hot.accept('./reducer.ts', module =>
    store.replaceReducer(
      persistReducer(
        {
          key,
          storage: localforage,
          whitelist,
        },
        module?.rootReducer
      )
    )
  );
}

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
