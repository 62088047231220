import type { Action } from '@reduxjs/toolkit';
import { REHYDRATE } from 'redux-persist';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RootState = any;

export function isHydrateAction(action: Action): action is Action<
  typeof REHYDRATE
> & {
  key: string;
  payload: RootState;
  err: unknown;
} {
  return action.type === REHYDRATE;
}
