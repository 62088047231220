import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ArgsInitSliceMeta,
  ReferenceOfMeta,
  dynamicFetchBaseFactory,
} from '@vs/rtk/utils';
import { getEnvConfig } from '@vs/utils/envConfig';

const referenceOfMeta: ReferenceOfMeta = {
  _deployEnv: undefined,
  _baseUrl: undefined,
  _getTokenFunc: undefined,
};

export function initSliceMeta({ deployEnv, getTokenFunc }: ArgsInitSliceMeta) {
  referenceOfMeta._deployEnv = deployEnv;
  referenceOfMeta._baseUrl = getEnvConfig({
    deployEnv,
  }).apiEndpoint.vs;
  referenceOfMeta._getTokenFunc = getTokenFunc;
}

export const apiMiscellaneousSlice = createApi({
  reducerPath: 'apiMiscellaneous',
  keepUnusedDataFor: 60,
  baseQuery: dynamicFetchBaseFactory(referenceOfMeta, {
    credentials: 'omit',
  }),
  endpoints: build => ({
    getCountry: build.query<{ viewerCountry: string | null }, void>({
      queryFn: async () => {
        const url = `https://assets.${referenceOfMeta._deployEnv}.viewsonic.cloud/country.png`;
        const response = await fetch(url, { method: 'GET', mode: 'cors' });
        const viewerCountry = response.headers.get('Viewer-Country');
        return { data: { viewerCountry: viewerCountry } };
      },
      extraOptions: {
        isPublicEndpoint: true,
      },
    }),
    // This endpoint is used on local dev mode, mainly for subscription e2e test
    activateTrialManually: build.mutation<void, ArgsActivateTrialManually>({
      query: ({ subscription_id }) => ({
        url: `/subscription/api/v1/resource/activate-trial`,
        method: 'PUT',
        body: {
          subscription_id,
        },
        headers: {
          'test-token': 'ci-test-bypass-trial',
        },
      }),
    }),
  }),
});

type ArgsActivateTrialManually = {
  subscription_id: string;
};

export const { useGetCountryQuery, useActivateTrialManuallyMutation } =
  apiMiscellaneousSlice;
