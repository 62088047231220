import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ArgsInitSliceMeta,
  ReferenceOfMeta,
  dynamicFetchBaseFactory,
  isHydrateAction,
} from '@vs/rtk/utils';
import { getEnvConfig } from '@vs/utils/envConfig';

const referenceOfMeta: ReferenceOfMeta = {
  _deployEnv: undefined,
  _baseUrl: undefined,
  _getTokenFunc: undefined,
};

export function initSliceMeta({ deployEnv, getTokenFunc }: ArgsInitSliceMeta) {
  referenceOfMeta._deployEnv = deployEnv;
  referenceOfMeta._baseUrl = getEnvConfig({ deployEnv }).apiEndpoint.vs;
  referenceOfMeta._getTokenFunc = getTokenFunc;
}

export function genReduxPersistKey() {
  return `${referenceOfMeta._deployEnv}-vs-account`;
}

export const vsAccount2ApiBase = createApi({
  reducerPath: 'vsAccount2GenApi',
  keepUnusedDataFor: 60,
  refetchOnMountOrArgChange: 60,
  baseQuery: dynamicFetchBaseFactory(referenceOfMeta, {
    credentials: 'include',
  }),
  /* Using redux-persist with RTK api slice should handling hydration manually, see:
     Issue -> https://github.com/reduxjs/redux-toolkit/issues/1400
     Example -> https://redux-toolkit.js.org/rtk-query/usage/persistence-and-rehydration
  */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extractRehydrationInfo(action, { reducerPath }): any {
    if (isHydrateAction(action)) {
      if (action.payload !== undefined && action.key === genReduxPersistKey()) {
        return action.payload[reducerPath];
      }
    }
  },
  endpoints: () => ({}),
});
