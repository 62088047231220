import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

import cssModule from './portal-toast.module.css';

export enum ToastVertical {
  top = 'top',
  bottom = 'bottom',
}
export enum ToastHorizontal {
  center = 'center',
  left = 'left',
  right = 'right',
}
export enum ActionTypes {
  close = 'close',
  reload = 'reload',
}

export interface PortalToastProps {
  open: boolean;
  vertical?: keyof typeof ToastVertical;
  horizontal?: keyof typeof ToastHorizontal;
  actionType?: keyof typeof ActionTypes | null;
  duration?: number | null | undefined;
  message?: string;
  onClose?: () => void;
}

export function PortalToast({
  open = false,
  vertical = 'top',
  horizontal = 'center',
  duration = null,
  message = 'Toast',
  actionType,
  onClose = () => {},
}: PortalToastProps) {
  const action = (() => {
    switch (actionType) {
      case 'close':
        return (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        );
      case 'reload':
        return (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => window.location.reload()}
          >
            <CachedIcon fontSize="small" />
          </IconButton>
        );
      default:
        return null;
    }
  })();
  return (
    <Snackbar
      className={cssModule['overwrite']}
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={action ? null : duration}
      onClose={!action && duration ? onClose : undefined}
      open={open}
      message={message}
      key={vertical + horizontal}
      action={action}
    />
  );
}

export default PortalToast;
