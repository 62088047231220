import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { Popover } from '@mui/material';
import { useAccountInfo } from '@vs/hooks/hook-account-info';
import { useIsEntitySignupFormUser } from '@vs/hooks/hook-is-entity-signup-form-user';
import { useSignOut } from '@vs/oidc-client';
import {
  useAccountGetEntityInfoQuery,
  useAvatarUrl,
} from '@vs/rtk/slices/apiVsAccount2';
import { PortalAvatarUserProfileCard } from '@vs/ui/portal/avatar-user-profile-card';
import { PortalButton } from '@vs/ui/portal/button';
import { PortalMenu } from '@vs/ui/portal/menu';
import { defaultAvatarUrl } from '@vs/utils/constants';
import { Env } from '@vs/utils/envConfig';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Img } from 'react-image';

import entityDefaultAvatar from '../assets/entity-management.png';
import styles from './portal-avatar-menu.module.css';

export type PortalAvatarMenuProps = {
  /** env config of app */
  env: Env;
  /** Should show user's entity information  */
  showEntityInfo?: boolean;
  /** Should show user's entity signup app entry */
  showEntitySignupEntry?: boolean;
};
/**
 * Vs portal avatar, account info and settings menu
 *
 * @export
 * @param {PortalAvatarMenuProps} props
 * @returns {ReactElement}
 */
export function PortalAvatarMenu(props: PortalAvatarMenuProps) {
  const { showEntityInfo = false, showEntitySignupEntry = false, env } = props;
  const { data: accountInfo } = useAccountInfo();
  const accountId = accountInfo?.id ?? '';
  const accountAvatarUrl = `${env.assets.vs}${useAvatarUrl({ width: 150, height: 150 }, accountId)}`;
  const signout = useSignOut();
  const { t } = useTranslation('shared');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const userName =
    `${accountInfo?.given_name ?? ''} ${accountInfo?.family_name ?? ''}`.trim();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles['avatar-container']}>
      <Img
        src={[accountAvatarUrl, defaultAvatarUrl]}
        alt={userName}
        width={38}
        height={38}
        className="cursor-pointer"
        onClick={handleClick}
      />
      <Popover
        disableAutoFocus
        className="avatar-menu-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="w-[320px] rounded-xl">
          <PortalAvatarUserProfileCard
            title={t('avatar_menu.account')}
            name={userName}
            avatarUrl={accountAvatarUrl}
            email={accountInfo?.email}
            link={{
              href: env?.appUrl?.accountPortal ?? '/account',
              label: t('avatar_menu.label.manage_account'),
            }}
          />
          {showEntityInfo && Boolean(accountId) && (
            <EntityInfoCard accountId={accountId} />
          )}
          <div onClick={handleClose}>
            {showEntitySignupEntry && <EntitySignupEntry />}
            <div className="flex justify-end py-1 px-6">
              <PortalButton
                onClick={() => signout()}
                label={t('avatar_menu.sign_out')}
                icon="logout"
              />
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
}

export default PortalAvatarMenu;

function EntityInfoCard({ accountId }: { accountId: string }) {
  const { t } = useTranslation('shared');
  const { data: accountEntityInfo } = useAccountGetEntityInfoQuery(
    {
      accountId,
    },
    { skip: !accountId }
  );
  const hasEntity = (accountEntityInfo?.data?.entities ?? []).length > 0;
  return (
    hasEntity && (
      <PortalAvatarUserProfileCard
        title={t('avatar_menu.entity')}
        name={accountEntityInfo?.data?.name || ''}
        avatarUrl={entityDefaultAvatar}
        link={{
          href: '/entity',
          label: t('avatar_menu.label.manage_entity'),
        }}
      />
    )
  );
}

function EntitySignupEntry() {
  const { t } = useTranslation('shared');
  const { isUser } = useIsEntitySignupFormUser();

  return (
    isUser && (
      <>
        <div className="vs-body-xs-regular text-vs-base-content-variant leading-5 px-6 pt-4">
          {t('avatar_menu.more_options')}
        </div>
        <PortalMenu
          menuItemList={[
            {
              name: t('avatar_menu.entity_signup_form'),
              icon: <AssignmentOutlinedIcon className={'me-2'} />,
              onClick: () => {
                window.open('/entity/sign-up-form', '_blank');
              },
            },
          ]}
          open={true}
        />
        <div className="border-vs-neut-300 border-t mx-6"></div>
      </>
    )
  );
}
