import { ProviderOidc } from '@vs/oidc-client';
import { ProviderSpaPathsConfig } from '@vs/shared-context/spa-path-config-provider';
import { TableActionStateProvider } from '@vs/shared-context/table-action-state-provider';
import { PortalBackdrop } from '@vs/ui/portal/backdrop';
import { createI18n } from '@vs/utils/i18n';
import { ProviderMuiTheme } from '@vs/utils/mui';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { basePath, localesFolderPath } from '../constants';
import { oidcConfig } from '../constants/oidc';
import { pathsConfig } from '../constants/pathsConfig';
import { persistor, store } from '../redux/store';
import AppAuthRoutes from './appContent/AppAuthRoutes';

const i18n = createI18n({
  localesFolderPath,
  defaultNS: 'vs-account',
});

export default function App() {
  return (
    <BrowserRouter basename={basePath}>
      <ProviderOidc config={oidcConfig}>
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <PersistGate
              loading={<PortalBackdrop type="app_init" open />}
              persistor={persistor}
            >
              <ProviderMuiTheme>
                <ProviderSpaPathsConfig configs={pathsConfig}>
                  <TableActionStateProvider>
                    <AppAuthRoutes />
                  </TableActionStateProvider>
                </ProviderSpaPathsConfig>
              </ProviderMuiTheme>
            </PersistGate>
          </Provider>
        </I18nextProvider>
      </ProviderOidc>
    </BrowserRouter>
  );
}
